import React from "react";
import styled from "styled-components";
import moment from 'moment'

const MessageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: ${({ isCustomer }) =>
    isCustomer ? "flex-start" : "flex-end"};
`;



const MessageAuthor = styled.small`
  position: absolute;
  top: -3px;
`;

const MessageText = styled.p`
  padding: 1rem;
  background: ${({ isCustomer, theme,color_mode }) =>
    isCustomer ? theme.secondary : color_mode};
  color: ${({ isCustomer }) => (isCustomer ? "black" : "white")};
  border-radius: 10px;
`;

const Message = ({ message, user,color_mode }) => {
  const userName = (user && user.user_name) || "";
    const event_created_date = message.event_created_date || "";
    const date=event_created_date.substring(0, 10)
    const time=event_created_date.substring(11, 19)
    const isCustomer = message && message.user_type === "customer";
    var convert_date = moment(event_created_date).format('YYYY-MM-DD HH:mm:ss')
  return (
    <MessageWrapper isCustomer={isCustomer} style={{margin:10}}>
      <MessageAuthor>{(message.user_type === 'agent') ? 'Medisave Support' : message.user_name} {convert_date} </MessageAuthor>
        <MessageText isCustomer={isCustomer} color_mode={color_mode}>{message.event_text} {}
        </MessageText>

    </MessageWrapper>
  );
};

export default Message;
