import React from "react";
import { TabsWrapper, TabsList, Tab } from "@livechat/design-system";
import styled from "styled-components";

const TabItem = styled(Tab)`
  margin-top: 0.5rem;
  padding: 0.5rem 1.5rem;
`;

// const LiveChatLogo = styled.a`
//   margin-left: auto;
  
//   & > img {
//     height: 25px;
//     margin-right: 2.5rem;
//     margin-top: 13px;
//     display: none;
//   }
// `;

const Navigation = ({ navigationItems, selectTab, activeTab }) => (
  <TabsWrapper>
    <TabsList style={{ paddingLeft: "1rem" }}>
      {navigationItems.map(item => (
        <TabItem
          key={item.id}
          onClick={() => selectTab(item.id)}
          isSelected={item.id === activeTab.id}
          description={(item && item.count) || null}
        >
          {item.title}
        </TabItem>
      ))}
    </TabsList>
  </TabsWrapper>
);

export default Navigation;
