import React from "react";
import styled from "styled-components";

const Link = styled.a`
  pointer-events: auto;
  cursor: pointer;
  color: rgb(67, 132, 245) !important;
  text-decoration: none !important;
  line-height: 1.27;
  display: inline-flex;
  word-break: break-all;
  width: 80%;
  float: left
`;

const RichMessageWrapper = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.05);
  display: block;
  max-width: 100%;
  margin-bottom: 0.1em;
  background-color: rgb(242, 243, 244);
  color: rgb(66, 77, 87);
  font-size: 15px;
  line-height: 1.5em;
  word-break: break-word;
  width: 230px;
  margin-top: 1rem;
`;

const RichMessageImg = styled.div`
  display: flex;
  margin: 0px auto;
  overflow: hidden;
  width: 100%;
  min-width: 110px;
  height: 150px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background: rgb(255, 255, 255);
`;
const RichMessageBtn = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  margin: 0px auto;
  overflow: hidden;
  width: 100%;
  height: 50px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background: rgb(255, 255, 255);
`;
const MessageWrapper = styled.div`
  padding: 20px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: ${({isCustomer}) =>
          isCustomer ? "flex-start" : "flex-end"};
`;

const MessageAuthor = styled.small`
  position: absolute;
  top: -3px;
`;

const RichMessage = ({message, user}) => {
        const userName = (user && user.user_name) || "";
        const isCustomer = user && user.user_type === "customer";
        const event_created_date = message.event_created_date || "";
        const date = event_created_date.substring(0, 10)
        const time = event_created_date.substring(12, 20)
        return (
            <MessageWrapper isCustomer={isCustomer}>
                <div><MessageAuthor>{userName} {date} {time}</MessageAuthor></div>
                <div>
                    {
                        (message && message.event_elements) ?
                            message.event_elements.map((elem, index) => {
                                return (

                                    <RichMessageWrapper>

                                        {
                                            (elem.image) ?
                                                <RichMessageImg>
                                                    <img src={elem.image.url} width={150}/>
                                                </RichMessageImg>
                                                : null
                                        }


                                        <div style={{padding: 20}}>
                                            <div>
                                                {elem.title}
                                            </div>
                                            <div style={{color: 'darkgrey'}}>
                                                {elem.subtitle}
                                            </div>
                                        </div>
                                        {
                                            elem.buttons.map((btn, index) => {
                                                return (
                                                    <RichMessageBtn>
                                                        {btn.text}
                                                    </RichMessageBtn>
                                                )
                                            })
                                        }
                                    </RichMessageWrapper>
                                )
                            })
                            : null
                    }

                </div>
            </MessageWrapper>
        )
    }
;

export default RichMessage;
