import React, {useEffect, useState} from "react";
import styled, {ThemeProvider} from "styled-components";

// LOGIC:
import {ChatSDK} from "./Logic";
import {useAuth} from "./Logic/authorization";

// COMPONENTS:
import ActiveChats from "./Container/ActiveChats";
import AgentDetails from "./Container/AgentDetails";
import Navigation from "./Components/Navigation";
import Loader from "./Components/Loader";
// STYLED COMPONENTS:
const AppWrapper = styled.div`
  background: ${({ theme }) => theme.bgGray};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

const Wrapper = styled.div`
  width: 95%;
  // max-width: 1024px;
  height: 100vh;
  min-width:1000px;
  max-height: 700px;
  background: ${({ theme }) => theme.bgWhite};
  box-sizing: border-box;
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.secondary};
  box-shadow: 0px 0px 15px 0px rgba(51, 51, 51, 0.1);
`;

// CONSTANTS:
const theme = {
  bgWhite: "#FFFFFF",
  bgGray: "#F0F4F7",
  primary: "#319C60",
  secondary: "#DDE2E6"
};

const navigationItems = [
  { id: "chats", title: "Active chats", component: ActiveChats },
  // { id: "traffic", title: "Traffic", component: Traffic },
  // { id: "archive", title: "Archive", component: Archives },
  { id: "agent", title: "Agent details", component: AgentDetails }
];

const App = () => {
  const { isLoggedIn, agentData } = useAuth();
  const [activeTab, setActiveTab] = useState(navigationItems[0]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [chatId, setChatId] = useState('');
  const [isReadyToRender, setIsReadyToRender] = useState(false);

  const selectActiveTab = (tabId,chatId='') => {
    const pickedTab = navigationItems.find(({ id }) => id === tabId);
    const pickedTabIndex = navigationItems.findIndex(({ id }) =>  id === tabId);
    setActiveTab(pickedTab);
    setActiveTabIndex(pickedTabIndex);
    // if(chatId!==''){
    //   setChatId(chatId);
    // }
  };

  useEffect(() => {
    if (isLoggedIn && agentData) {
      ChatSDK.init(agentData);

      return ChatSDK.destroy;
    }
  }, [agentData, isLoggedIn]);

  useEffect(() => {
    let isMounted = true;

    // Wait with render until app will get all necessary info from API
    ChatSDK.on("ready", () => {
      if (isMounted) {
        setIsReadyToRender(true);
      }
    });
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }


    return () => (isMounted = false);
  }, []);

  const ActiveComponent = activeTab && activeTab.component;

  return (
    <ThemeProvider theme={theme}>
      <AppWrapper>
        <Wrapper>
          <Navigation
            navigationItems={navigationItems}
            selectTab={selectActiveTab}
            activeTab={activeTab}
          />
          <span style={{display:(activeTabIndex===0)?'':'none'}}>
            {isReadyToRender  ? <ActiveChats select_tab={selectActiveTab} select_chat_id={chatId}/> : null}
          </span>
          {/*{isReadyToRender && activeTabIndex===1  ? <Traffic select_tab={selectActiveTab} select_chat_id={chatId}/> : null}*/}
          {/*{isReadyToRender && activeTabIndex===2  ? <Archives select_tab={selectActiveTab} select_chat_id={chatId}/> : null}*/}
          {isReadyToRender && activeTabIndex===1  ? <AgentDetails select_tab={selectActiveTab} select_chat_id={chatId}/> : null}
          {!isReadyToRender?<Loader/>:null}
        </Wrapper>
      </AppWrapper>
    </ThemeProvider>
  );
};

export default App;
