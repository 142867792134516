import {useEffect} from "react";
import {ChatSDK} from "../Logic";
import mp3_incoming_chat from '../Media/incoming_chat.mp3'
import mp3_message from '../Media/message.mp3'

/**
 * Handle chat messages related to passed chatId
 * @param {*} chatId
 */
export function useChatMessages() {
    const audioTune = new Audio(mp3_message);
    useEffect(() => {
        audioTune.load();
        if (window.location.href.includes('chat.happypocket2u.com')) {
            setInterval(function () {
                window.location.reload(false);
            }, 3600000);
        }
    }, [])
    useEffect(() => {
            let isMounted = true;

            const notify = (data) => {
                let text = '';
                let msg_type = '';
                if (data && data.payload && data.payload.event && data.payload.event.text) text = data.payload.event.text;
                if (data && data.payload && data.payload.event && data.payload.event.type) msg_type = data.payload.event.type;

                if (text !== '' && msg_type !== 'system_message') {

                    audioTune.play();
                    var options = {
                        body: text,
                        icon: "http://happypocket2u.com/livechat/medisave_logo-08.png",
                        dir: "ltr"
                    };
                    var notification = new Notification("Medisave Live Chat", options);
                    setTimeout(() => notification.close(), 5 * 1000);
                    notification.addEventListener('click', () => {
                        window.open(window.location, '_blank');
                    });
                }
            }

            const notify2 = (data) => {
                let text = '';
                if (data && data.payload && data.payload.chat && data.payload.chat.thread && data.payload.chat.thread.events) text = data.payload.chat.thread.events [data.payload.chat.thread.events.length - 1].text;
                if (text !== '') {
                    audioTune.play();
                    var options = {
                        body: text,
                        icon: "http://happypocket2u.com/livechat/medisave_logo-08.png",
                        dir: "ltr"
                    };
                    var notification = new Notification("Medisave Live Chat", options);
                    setTimeout(() => notification.close(), 5 * 1000);
                    notification.addEventListener('click', () => {
                        window.open(window.location, '_blank');
                    });
                }
            }

            const insertIntoDB = (data) => {
                var requestOptions = {
                    method: 'POST',
                    body: JSON.stringify(data),
                    redirect: 'follow'
                };

                fetch("https://chatapi.happypocket2u.com/", requestOptions)
                    .then(response => response.text())
                    .then(result => console.log(result))
                    .catch(error => console.log('error', error));
            }

            // Listen to incoming messages
            ChatSDK.on("incoming_event", insertIntoDB);
            ChatSDK.on("incoming_chat_thread", insertIntoDB);
            ChatSDK.on("chat_thread_properties_updated", insertIntoDB);
            ChatSDK.on("customer_updated", insertIntoDB);
            ChatSDK.on("incoming_chat_thread", notify2);
            ChatSDK.on("incoming_event", notify);

            return () => {


                ChatSDK.off("incoming_event", insertIntoDB);
                ChatSDK.off("incoming_chat_thread", insertIntoDB);
                ChatSDK.off("chat_thread_properties_updated", insertIntoDB);
                ChatSDK.off("customer_updated", insertIntoDB);
                ChatSDK.off("incoming_chat_thread", notify2);
                ChatSDK.off("incoming_event", notify);
                isMounted = false
            };
        }

        ,
        []
    )
    ;

}

/**
 * Handle changes on active chat list
 * - add new chats to list
 * - remove closed chats from list
 * @param {function} pickChat - function that pick chat from list
 */
export function useChatList() {
    const audioTune = new Audio(mp3_incoming_chat);
    useEffect(() => {
        audioTune.load();
    }, [])
    useEffect(() => {
        let isMounted = true;
        const notify = (data) => {
            let text = '';
            let msg_type = '';
            if (data && data.payload && data.payload.event && data.payload.event.text) text = data.payload.event.text;
            if (data && data.payload && data.payload.event && data.payload.event.type) msg_type = data.payload.event.type;

            if (text !== '' && msg_type !== 'system_message') {
                audioTune.play();
                var options = {
                    body: text,
                    icon: "http://happypocket2u.com/livechat/medisave_logo-08.png",
                    dir: "ltr"
                };
                var notification = new Notification("Medisave Live Chat", options);
                setTimeout(() => notification.close(), 5 * 1000);
                notification.addEventListener('click', () => {
                    window.open(window.location, '_blank');
                });
            }
        }
        const notify2 = (data) => {
            let text = '';
            if (data && data.payload && data.payload.chat && data.payload.chat.thread && data.payload.chat.thread.events) text = data.payload.chat.thread.events [data.payload.chat.thread.events.length - 1].text;
            if (text !== '') {
                audioTune.play();
                var options = {
                    body: text,
                    icon: "http://happypocket2u.com/livechat/medisave_logo-08.png",
                    dir: "ltr"
                };
                var notification = new Notification("Medisave Live Chat", options);
                setTimeout(() => notification.close(), 5 * 1000);
                notification.addEventListener('click', () => {
                    window.open(window.location, '_blank');
                });
            }
        }
        const insertIntoDB = (data) => {

            var requestOptions = {
                method: 'POST',
                body: JSON.stringify(data),
                redirect: 'follow'
            };

            fetch("https://chatapi.happypocket2u.com/", requestOptions)
                .then(response => response.text())
                .then(result => console.log(result))
                .catch(error => console.log('error', error));
        }

        // Listen to incoming messages
        ChatSDK.on("incoming_event", insertIntoDB);
        ChatSDK.on("incoming_chat_thread", insertIntoDB);
        ChatSDK.on("customer_updated", insertIntoDB);
        ChatSDK.on("chat_thread_properties_updated", insertIntoDB);
        ChatSDK.on("incoming_chat_thread", notify2);
        ChatSDK.on("incoming_event", notify);


        return () => {
            ChatSDK.off("incoming_event", insertIntoDB);
            ChatSDK.off("incoming_chat_thread", insertIntoDB);
            ChatSDK.off("customer_updated", insertIntoDB);
            ChatSDK.off("chat_thread_properties_updated", insertIntoDB);
            ChatSDK.off("incoming_chat_thread", notify2);
            ChatSDK.off("incoming_event", notify);
            isMounted = false;
        };
    }, []);

}