import React from "react";
import styled from "styled-components";

const InstructionWrapper = styled.div`
  width: 100%;
  text-align: center;
  color: gray;
`;

const ChatInstruction = () => (
  <InstructionWrapper>
      <div><img src={'https://iconfont.alicdn.com/s/ca94519a-b889-46d6-84c4-ba3681550f4e_origin.svg'} width={350}/>   <h3 >
          Currently no customer
      </h3></div>


  </InstructionWrapper>
);

export default ChatInstruction;
