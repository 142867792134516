import React, {useCallback, useEffect, useState} from "react";
import {
    ActionModal,
    Badge,
    Button,
    Card,
    Divider,
    FieldGroup,
    InputField,
    Loader,
    NumericInputField,
    Promo,
    Tab,
    TabsList,
    TabsWrapper,
    Toast
} from "@livechat/design-system";
import styled from "styled-components";
import {FaCartPlus, FaCheckCircle, FaPaperclip, FaSearch, FaTelegramPlane, FaTrash, FaTrashAlt,} from "react-icons/fa";
import axios from 'axios';
import Iframe from 'react-iframe'

const PluginHomeWrapper = styled.div`
  height: ${({onlyMessages}) => (onlyMessages ? "100%" : "85%")};
  float: left;
  width: 37%;
  padding: 10px;
`;

const PluginHome = ({chatMessages, chatInfo, domain, color_mode}) => {
    const chatMessagesClone = [...chatMessages]

    const [MEDISAVE_CART_TEMP, SET_MEDISAVE_CART_TEMP] = useState('');
    const [MEDISAVE_TOKEN_TEMP, SET_MEDISAVE_TOKEN_TEMP] = useState('');
    const [PHPSESSID, SET_PHPSESSID] = useState('');
    const [escape_words, set_escape_words] = useState([]);

    // console.log(chatInfo)
    // console.log(chatMessages)
    useEffect(() => {
        var formdata = new FormData();
        formdata.append("action", "retrieve_exclude_words");

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://chatapi.happypocket2u.com/get_info.php", requestOptions)
            .then(response => response.json())
            .then(result => set_escape_words(result.data))
            .catch(error => console.log('error', error));
    }, [])
    useEffect(() => {
            if (chatInfo) {
                if (chatInfo) {
                    if (chatInfo.fields) {
                        if (chatInfo.fields.MEDISAVE_CART_TEMP) SET_MEDISAVE_CART_TEMP(chatInfo.fields.MEDISAVE_CART_TEMP);
                        if (chatInfo.fields.MEDISAVE_TOKEN_TEMP) SET_MEDISAVE_TOKEN_TEMP(chatInfo.fields.MEDISAVE_TOKEN_TEMP);
                        if (chatInfo.fields.PHPSESSID) SET_PHPSESSID(chatInfo.fields.PHPSESSID);
                        if (chatInfo.users[0].id) get_customer_info(chatInfo.user_id)
                    }

                }

            }
            chatMessagesClone.reverse()
            let final_text = '';
            let final_int = 0;
            let customer_msg_count = 0;

            chatMessagesClone.slice(0).reverse().map((message, index) => {
                let is_customer_chat = false;
                if (message.properties) {
                    if (message.properties.source) {
                        if (message.properties.source.client_id) {
                            is_customer_chat = true
                        }
                    }
                }
                const user = getChatUser(message.author_id);

                let message_arr = [];
                if (message.event_text) {
                    message_arr = message.event_text.split(' ');
                }
                message_arr = message_arr.filter((word) => (!escape_words.includes(word)))

                if (is_customer_chat && user.type !== 'agent') {
                    if (message_arr.length > 0) {
                        customer_msg_count += 1;
                    }
                    message_arr.map((text, index) => {
                        if (!isNaN(text)) {
                            final_int = (text)
                        } else {
                            final_text = text;
                        }
                    })
                }

            })

            set_chat_notification_badge_count(customer_msg_count)
            set_search_value(final_text);
            set_quantity_value(final_int);
        }
        , [chatMessages]);

    // ChatSDK.getAgentDetails().then(details => {
    //     console.log(details)
    // });
    // useEffect(()=>{
    //     // getCart();
    // })

    const [selectedTab, set_selectedTab] = useState(() => {
        return 'chats'
    });
    const [search_value, set_search_value] = useState("");
    const [new_adddress, set_new_address] = useState("");
    const [new_adddress_error, set_new_address_error] = useState("");
    const [customer_info, set_customer_info] = useState(null);
    const [chat_notification_badge_count, set_chat_notification_badge_count] = useState(0);
    const [search_notification_badge_count, set_search_notification_badge_count] = useState(0);
    const get_customer_info = (customer_id) => {
        var myHeaders = new Headers();
        let token = localStorage.getItem('accessToken')
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", 'Bearer ' + token);
        var raw = JSON.stringify({"customer_id": customer_id});

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://api.livechatinc.com/v3.2/agent/action/get_customer", requestOptions)
            .then(response => response.text())
            .then(result => {
                set_customer_info(JSON.parse(result))
            })
            .catch(error => console.log('error', error));
    }
    const [search_value_error, set_search_value_erro] = useState("");
    const [quantity_value, set_quantity_value] = useState('0');
    const [quantity_value_error, set_quantity_value_erro] = useState("");
    const [is_search, set_search] = useState(false);
    const [cart_html, set_cart_html] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isAdded, setIsAdded] = useState(false);
    const [search_result, set_search_result] = useState(() => {
        return [];
    })
    const [list, set_list] = useState([]);
    const [cart, set_cart] = useState([]);
    let items = [
        {id: "chats", title: "Chats", count: chat_notification_badge_count},
        {id: "search", title: "Search", count: search_result.length},
        {id: "list", title: "List", count: list.length},
        {id: "cart", title: "Cart"},
        {id: "address", title: "Company Details"},
        {id: "upload_documents", title: "Upload Documents"},
        {id: "register", title: "Register"},
    ];
    const getChatUser = authorId => {
        const chatUsers = chatInfo.users;
        if (chatUsers) {
            return chatUsers.find(({id}) => id === authorId) || {
                type: "customer"
            };
        } else {
            return {
                type: ""
            };
        }
    };
    const onSearchQuantityInputChange = useCallback(
        (value, search_result_index, result_list_index) => {
            let temp_search_result = [].concat(search_result)
            temp_search_result[search_result_index]['result'][result_list_index]['qty'] = value
            // console.log( temp_search_result)
            // console.log( search_result[search_result_index]['result'][result_list_index])
            set_search_result(temp_search_result)
            // set_quantity_value(value);
        },
        [search_result],
    );
    const onListQuantityInputChange = useCallback(
        (value, list_index) => {
            let temp_list = [].concat(list)
            temp_list[list_index]['qty'] = value
            // temp_search_result[search_result_index]['result'][result_list_index]['qty']=value
            // console.log( list)
            // console.log( search_result[search_result_index]['result'][result_list_index])
            // set_search_result(temp_search_result)
            // set_quantity_value(value);
            set_list(temp_list)
        },
        [list],
    );
    const onInputChange = useCallback(
        (e) => {
            set_search_value(e.target.value);
        },
        [],
    );
    const handleModalOpen = () => setIsOpen(true);

    const handleModalClose = () => setIsOpen(false);
    const onSearch =
        () => {
            set_search(true)
            let url = `https://backstage.happypocket2u.com/api/${domain}/product/search/${search_value}`
            // let url = 'https://medisave.pro/api/product/search_allvariant/' + search_value;
            axios.get(url)
                .then(async res => {
                    set_search(false)
                    await set_search_result(prevState => ([{
                            search_word: search_value,
                            result: res.data.result.products
                        }, ...prevState]
                    ))
                    set_search_notification_badge_count(res.data.result.products.length);
                    // console.log(search_result)
                })

        }
    const getCart =
        () => {
            let url = 'https://medisave.pro/cart';
            axios.get(url)
                .then(async res => {
                    set_cart_html(res.data)
                })

        }

    const add_to_list = (product) => {
        if (product) {
            set_list(prev_state => [...prev_state, product])
            // console.log(cart)
            setIsAdded(true)
            setTimeout(function () {
                setIsAdded(false)
            }, 2000);
        }

    }
    const delete_list = (index) => {
        if (index >= 0) {
            var array = [...list]
            array.splice(index, 1);
            set_list(array);
        }

    }
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            set_selectedTab('search');
            onSearch();
        }
    }
    const sendRichMessage = useCallback((product_list) => {
        let token = localStorage.getItem('accessToken')
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", 'Bearer ' + token);
        let cart_item = [];
        product_list.map((product, index) => {
            let img_url = 'https://livechat.happypocket2u.com/logo_square.png';
            if (product.asset_name) img_url = "https://medisave.happypocket2u.com/public/assets/" + product.id + '/' + escape(product.asset_name)
            cart_item.push({
                "title": product.product_name + ' - ' + product.brand + ' (' + product.dosage + ') x ' + product.qty,
                "subtitle": 'MYR ' + product.selling_price + 'each',
                "image": {
                    "url": img_url
                },
                "buttons": [{
                    "type": "url",
                    "text": "Add to cart",
                    "postback_id": 'add_to_cart',
                    "user_ids": [],
                    "value": `https://chatresponse.happypocket2u.com/add_to_cart.php?product_id=${product.id}&product_variant_id=${product.pid_detail}&qty=${product.qty}&domain=${domain}&cart=${MEDISAVE_CART_TEMP}&token=${MEDISAVE_TOKEN_TEMP}&PHPSESSID=${PHPSESSID}`
                },
                    {
                        "type": "url",
                        "text": "View Cart",
                        "postback_id": 'open_url',
                        "user_ids": [],
                        "value": 'https://medisave.pro/cart'
                    },
                ]
            })
        })
        var raw = JSON.stringify({
            "chat_id": chatInfo.chat_id,
            "event": {
                "type": "rich_message",
                "recipients": "all",
                "template_id": "cards",
                "elements": cart_item
            }
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://api.livechatinc.com/v3.2/agent/action/send_event",
            requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.error) {
                    alert(result.error.message)
                } else {
                    setIsOpen(true)
                    // result.text();
                    set_list([])
                }

            })
            .catch(response => {
                alert(response.message)
            });

    }, [MEDISAVE_CART_TEMP, MEDISAVE_TOKEN_TEMP, PHPSESSID])

    const delete_search_result = (index) => {
        let array = [...search_result];
        array.splice(index, 1);
        console.log(array)
        set_search_result(array)
    }

    const onNewAddInputChange = (e) => {
        set_new_address(e.target.value)
    }
    const remove_word = (word) => {
        var formdata = new FormData();
        formdata.append("action", "insert_exclude_words");
        formdata.append("exclude_word", word);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://chatapi.happypocket2u.com/get_info.php", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    set_escape_words(prevState => [...prevState, word])
                }
            })
            .catch(error => console.log('error', error));

    }
    return (
        <PluginHomeWrapper>
            {
                (isOpen) ?
                    <ActionModal
                        onClose={handleModalClose}
                        style={{width: "410px"}}
                        heading="Success"
                        actions={
                            <React.Fragment>
                                <Button
                                    size="large"
                                    onClick={handleModalClose}
                                    kind="secondary"
                                >
                                    OK
                                </Button>

                            </React.Fragment>
                        }
                        icon={<FaCheckCircle height={100} width={100} size={100} fill={'#2a9558'}/>}
                    >
                        Sent message successfully
                    </ActionModal> : null
            }
            <TabsWrapper style={{overflowX: 'scroll', overflowY: 'hidden'}}>
                <TabsList>
                    {items.map(({id, count, title}) => (
                        <Tab
                            onClick={() => set_selectedTab(id)}
                            key={id}
                            isSelected={selectedTab === id}
                            description={count}
                        >
                            {title}
                        </Tab>
                    ))}
                </TabsList>
            </TabsWrapper>

            {
                (selectedTab === 'chats') ?
                    <div>
                        <div style={{display: 'block', height: 390, overflowY: 'auto'}}>
                            {chatMessages &&
                            chatMessages.slice(0).reverse().map((message, index) => {
                                let is_customer_chat = false;
                                if (message.user_type == 'customer') is_customer_chat = true


                                let message_arr = [];
                                if (message.event_text && message.event_type === 'message') {
                                    message_arr = message.event_text.split(' ');
                                    message_arr = message_arr.filter((word) => (!escape_words.includes(word)))
                                }
                                return (
                                    (is_customer_chat && message.user_type !== 'agent' && message.event_type === 'message' && message_arr.length > 0) ?
                                        <Card key={index}>
                                            <div>
                                                <p>
                                                    {
                                                        message_arr.map((text, index) => {
                                                            return <Button
                                                                onClick={() => {

                                                                        set_search_value(text)
                                                                }}
                                                                key={index}
                                                                style={{margin: 4}}> {text} <Badge
                                                                secondary onClick={() => remove_word(text)}>x</Badge>
                                                            </Button>
                                                        })
                                                    }
                                                </p>

                                            </div>
                                        </Card>
                                        : null
                                )

                            })
                            }
                        </div>

                    </div>
                    : null
            }
            {
                (selectedTab === 'search') ?
                    <div>
                        {(isAdded) ?
                            <Toast style={{margin: 20, position: 'absolute', zIndex: 9999}}
                                   variant='success'
                                   onClose={() => setIsAdded(false)}
                                   removable>Item Added.</Toast>
                            : null
                        }
                        <div style={{display: 'block', height: 390, overflowY: 'auto'}}>
                            {(is_search) ?
                                <div style={{padding: 20, textAlign: 'center'}}><Loader size="medium"/></div> : null}
                            {
                                (search_result) ?
                                    (search_result.length > 0) ?
                                        search_result.map((result_list, search_result_index) => {
                                            return (
                                                <div key={search_result_index}>
                                                    <Button kind="primary" style={{
                                                        width: '100%',
                                                        marginTop: 10
                                                    }}
                                                            onClick={() => {
                                                                delete_search_result(search_result_index)
                                                            }}
                                                    >{result_list.search_word} <FaTrashAlt/></Button>
                                                    {
                                                        (result_list.result.length > 0) ?
                                                            result_list.result.map((product, result_list_index) => {
                                                                return (<Card key={result_list_index}
                                                                              title={product.product_name}>
                                                                        <div style={{float: 'left', margin: 5}}>
                                                                            {
                                                                                (product.asset_name) ?
                                                                                    <img width={50}
                                                                                         src={"https://medisave.happypocket2u.com/public/assets/" + product.id + '/' + escape(product.asset_name)}/>
                                                                                    :
                                                                                    null
                                                                            }

                                                                        </div>
                                                                        <div style={{float: 'left', maxWidth: 275}}>
                                                                            <p style={{
                                                                                margin: 0,
                                                                                padding: 0,
                                                                                fontSize: 12
                                                                            }}>
                                                                                Brand: <strong
                                                                                style={{color: color_mode}}>{product.brand}</strong>
                                                                            </p>
                                                                            {/*<p style={{*/}
                                                                            {/*    margin: 0,*/}
                                                                            {/*    padding: 0,*/}
                                                                            {/*    fontSize: 12*/}
                                                                            {/*}}>*/}
                                                                            {/*    Ingredient: <strong*/}
                                                                            {/*    style={{color: color_mode}}>{product.active_ingredient}</strong>*/}
                                                                            {/*</p>*/}
                                                                            <p style={{
                                                                                margin: 0,
                                                                                padding: 0,
                                                                                fontSize: 12
                                                                            }}>
                                                                                Dosage: <strong
                                                                                style={{color: color_mode}}>{product.dosage}</strong>
                                                                            </p>
                                                                            <p style={{
                                                                                margin: 0,
                                                                                padding: 0,
                                                                                fontSize: 12
                                                                            }}>
                                                                                Cost Price: <strong
                                                                                style={{color: color_mode}}>{(product.cost_price) ? `MYR ${product.cost_price}` : '-'}</strong>
                                                                            </p>
                                                                            <p style={{
                                                                                margin: 0,
                                                                                padding: 0,
                                                                                fontSize: 12
                                                                            }}>
                                                                                Selling Price: <strong
                                                                                style={{color: color_mode}}>MYR {product.selling_price}</strong>
                                                                            </p>
                                                                            {
                                                                                (product.promo_value) ?
                                                                                    <p style={{
                                                                                        margin: 0,
                                                                                        padding: 0,
                                                                                        fontSize: 12
                                                                                    }}>
                                                                                        Promo Price: <strong
                                                                                        style={{color: color_mode}}>MYR {parseFloat(product.promo_value).toFixed(2)}</strong>
                                                                                    </p>
                                                                                    : null
                                                                            }

                                                                            <p style={{
                                                                                margin: 0,
                                                                                padding: 0,
                                                                                fontSize: 12
                                                                            }}>
                                                                                Expired At: <strong
                                                                                style={{color: color_mode}}>{(product.expired_at) ? product.expired_at : '-'}</strong>
                                                                            </p>

                                                                            {
                                                                                (parseInt(product.status) === 0) ?

                                                                                    <div style={{
                                                                                        paddingRight: "10px",
                                                                                        display: "inline-block"
                                                                                    }}>
                                                                                        <div style={{float: 'left'}}>
                                                                                            <NumericInputField
                                                                                                id="quantity_input"
                                                                                                min={0}
                                                                                                max={10}
                                                                                                value={(product.qty) ? product.qty : 0}
                                                                                                onChange={(value) => onSearchQuantityInputChange(value, search_result_index, result_list_index)}
                                                                                            />
                                                                                        </div>
                                                                                        <div style={{float: 'right'}}>
                                                                                            <Button
                                                                                                disabled={((product.qty) && (product.qty > 0)) ? false : true}
                                                                                                onClick={() => add_to_list(product)}><FaCartPlus/></Button>
                                                                                            <Button
                                                                                                disabled={((product.qty) && (product.qty > 0)) ? false : true}
                                                                                                onClick={() => sendRichMessage([product])}><FaTelegramPlane/></Button>
                                                                                        </div>

                                                                                    </div>
                                                                                    :
                                                                                    <div>Not available</div>
                                                                            }


                                                                        </div>
                                                                    </Card>
                                                                )
                                                            })
                                                            : <div>
                                                                <Promo size="large" header="No Result"
                                                                       onClose={() => delete_search_result(search_result_index)}> Please
                                                                    search with other words </Promo>
                                                            </div>
                                                    }
                                                </div>
                                            )
                                        })
                                        :
                                        <div style={{float: 'left', margin: 5}}>
                                            <div><img
                                                src={'https://iconfont.alicdn.com/s/c0fc3f7e-3ca7-4028-893f-cc936ba955b4_origin.svg'}
                                                width={200}/></div>
                                            <div style={{fontColor: 'grey'}}>Please start the search</div>
                                        </div>

                                    :
                                    null
                            }
                        </div>

                    </div>
                    :
                    null
            }
            {
                (selectedTab === 'list') ?
                    <div>
                        <div style={{display: 'block', height: 390, overflowY: 'auto'}}>
                            {
                                (list) ?
                                    (list.length > 0) ?
                                        list.reverse().map((product, product_index) => {
                                            return (<Card key={product_index} title={product.product_name}>
                                                    <div style={{float: 'left', margin: 5}}>
                                                        {
                                                            (product.asset_name) ?
                                                                <img width={50}
                                                                     src={"https://medisave.happypocket2u.com/public/assets/" + product.id + '/' + escape(product.asset_name)}/>
                                                                : null
                                                        }

                                                    </div>
                                                    <div style={{float: 'left'}}>
                                                        <p style={{margin: 0, padding: 0, fontSize: 12}}>
                                                            Brand: <strong
                                                            style={{color: color_mode}}>{product.brand}</strong>
                                                        </p>
                                                        {/*<p style={{margin: 0, padding: 0, fontSize: 12}}>*/}
                                                        {/*    Ingredient: <strong*/}
                                                        {/*    style={{color: color_mode}}>{product.active_ingredient}</strong>*/}
                                                        {/*</p>*/}
                                                        <p style={{margin: 0, padding: 0, fontSize: 12}}>
                                                            Dosage: <strong
                                                            style={{color: color_mode}}>{product.dosage}</strong>
                                                        </p>
                                                        <p style={{margin: 0, padding: 0, fontSize: 12}}>
                                                            Selling Price: <strong
                                                            style={{color: color_mode}}>MYR {product.selling_price}</strong>
                                                        </p>
                                                        {
                                                            (product.promo_value) ?
                                                                <p style={{margin: 0, padding: 0, fontSize: 12}}>
                                                                    Promo Price: <strong
                                                                    style={{color: color_mode}}>MYR {parseFloat(product.promo_value).toFixed(2)}</strong>
                                                                </p>
                                                                : null
                                                        }

                                                        <p style={{margin: 0, padding: 0, fontSize: 12}}>
                                                            Expiry: <strong
                                                            style={{color: color_mode}}>{(product.expiry_date) ? product.expiry_date : '-'}</strong>
                                                        </p>

                                                        <div style={{
                                                            paddingRight: "10px",
                                                            display: "inline-block"
                                                        }}>
                                                            <div style={{float: 'left'}}>
                                                                <NumericInputField
                                                                    id="quantity_input"
                                                                    min={0}
                                                                    max={10}
                                                                    value={(product.qty) ? product.qty : 1}
                                                                    onChange={(value) => onListQuantityInputChange(value, product_index)}
                                                                />
                                                            </div>
                                                            <div style={{float: 'right'}}>
                                                                <Button onClick={() => delete_list(product_index)}
                                                                        style={{borderWidth: 0}}><FaTrash
                                                                    style={{color: 'red'}}/></Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>
                                            )
                                        }) :
                                        <div style={{padding: 20, textAlign: 'center'}}>
                                            <div><img
                                                src={'https://iconfont.alicdn.com/s/c0fc3f7e-3ca7-4028-893f-cc936ba955b4_origin.svg'}
                                                width={150}/></div>
                                            <div style={{fontColor: 'grey'}}>Please add item to list</div>
                                        </div>
                                    : null

                            }


                        </div>
                    </div>
                    : null
            }


            {
                (selectedTab === 'cart') ?
                    <div>
                        <div style={{display: 'block', height: 390, overflowY: 'auto', padding: 10}}>
                            <div style={{display: 'block', height: 390, overflowY: 'auto'}}>
                                {
                                    (list) ?
                                        (list.length > 0) ?
                                            list.reverse().map((product, index) => {
                                                return (<Card key={index} title={product.product_name}>
                                                        <div style={{padding: 20, textAlign: 'center'}}>
                                                            {
                                                                (product.asset_name) ?
                                                                    <img width={50}
                                                                         src={"https://medisave.happypocket2u.com/public/assets/" + product.id + '/' + escape(product.asset_name)}/>
                                                                    : null
                                                            }

                                                        </div>
                                                        <div style={{float: 'left'}}>
                                                            <p style={{margin: 0, padding: 0, fontSize: 12}}>
                                                                Brand: <strong
                                                                style={{color: color_mode}}>{product.brand}</strong>
                                                            </p>
                                                            <p style={{margin: 0, padding: 0, fontSize: 12}}>
                                                                Dosage: <strong
                                                                style={{color: color_mode}}>{product.dosage}</strong>
                                                            </p>
                                                            <p style={{margin: 0, padding: 0, fontSize: 12}}>
                                                                Price: <strong
                                                                style={{color: color_mode}}>MYR {product.price1}</strong>
                                                            </p>
                                                            <div style={{float: 'right'}}>
                                                                <Button onClick={() => delete_list(index)}
                                                                        style={{borderWidth: 0}}><FaTrash
                                                                    style={{color: 'red'}}/></Button>
                                                            </div>

                                                        </div>
                                                    </Card>
                                                )
                                            }) :
                                            <div style={{padding: 20, textAlign: 'center'}}>
                                                <div><img
                                                    src={'https://iconfont.alicdn.com/s/c0fc3f7e-3ca7-4028-893f-cc936ba955b4_origin.svg'}
                                                    width={50}/></div>
                                                <div style={{fontColor: 'grey'}}>Please add item to list</div>
                                            </div>
                                        : null

                                }


                            </div>

                        </div>
                    </div>
                    : null
            }
            {
                (selectedTab === 'address') ?
                    <div>
                        <div style={{display: 'block', height: 390, overflowY: 'auto', padding: 10}}>
                            <InputField
                                // value={company_name_input}
                                labelText='Company Name'
                                // error={}
                                id='company_name_input'
                                placeholder='Company Name'
                                // onChange={onInputChange}
                                style={{width: '100%'}}
                            />
                            <InputField
                                // value={company_name_input}
                                labelText='Company Registration No'
                                // error={}
                                id='company_name_input'
                                placeholder='Company Registration No'
                                // onChange={onInputChange}
                                style={{width: '100%'}}
                            />
                            <InputField
                                // value={company_name_input}
                                labelText='Address Line 1'
                                // error={}
                                id='company_name_input'
                                placeholder='Address Line 1'
                                // onChange={onInputChange}
                                style={{width: '100%'}}
                            />
                            <InputField
                                // value={company_name_input}
                                labelText='Address Line 2'
                                // error={}
                                id='company_name_input'
                                placeholder='Address Line 2'
                                // onChange={onInputChange}
                                style={{width: '100%'}}
                            />
                            <InputField
                                // value={company_name_input}
                                labelText='City'
                                // error={}
                                id='company_name_input'
                                placeholder='City'
                                // onChange={onInputChange}
                                style={{width: '100%'}}
                            />
                            <InputField
                                // value={company_name_input}
                                labelText='Postcode'
                                // error={}
                                id='company_name_input'
                                placeholder='Postcode'
                                // onChange={onInputChange}
                                style={{width: '100%'}}
                            />
                            <InputField
                                // value={company_name_input}
                                labelText='State'
                                // error={}
                                id='company_name_input'
                                placeholder='State'
                                // onChange={onInputChange}
                                style={{width: '100%'}}
                            />
                            <InputField
                                // value={company_name_input}
                                labelText='Country'
                                // error={}
                                id='company_name_input'
                                placeholder='Country'
                                // onChange={onInputChange}
                                style={{width: '100%'}}
                            />
                            <InputField
                                // value={company_name_input}
                                labelText='Phone Number'
                                // error={}
                                id='company_name_input'
                                placeholder='Phone Number'
                                // onChange={onInputChange}
                                style={{width: '100%'}}
                            />
                            <Button
                                kind="primary"
                                onClick={() => {

                                }} style={{width: '100%'}}>
                                Submit
                            </Button>
                        </div>
                    </div>
                    : null
            }
            {
                (selectedTab === 'upload_documents') ?
                    <div>
                        <div style={{display: 'block', height: 390, overflowY: 'auto', padding: 10}}>

                            <Button

                                onClick={() => {

                                }} style={{width: '100%', height: 100}}>
                                <FaPaperclip color={color_mode}/> Upload Documents

                            </Button>
                        </div>
                    </div>
                    : null
            }
            {
                (selectedTab === 'register') ?
                    <div>
                        <div style={{display: 'block', height: 400,}}>
                            <Iframe url="https://medisave.pro/signup"
                                    width="100%"
                                    height="400"
                                    id="register"
                                    className="myClassname"
                                    display="initial"
                                    frameBorder={0}
                                    position="relative"/>
                        </div>
                    </div>
                    : null
            }

            {
                (selectedTab === 'customer' && customer_info) ?
                    <div id={'customer'}>
                        <div style={{display: 'block', height: 390, overflowY: 'auto'}}>
                            {customer_info}
                            <Card title="General Info">
                                <div>
                                    <table>
                                        <tr>
                                            <td>Name:</td>
                                            <td>{customer_info.name}</td>
                                        </tr>
                                        {
                                            (customer_info.last_visit.geolocation) ?
                                                <tr>
                                                    <td>Location:</td>
                                                    <td>{customer_info.last_visit.geolocation.region},{customer_info.last_visit.geolocation.city},{customer_info.last_visit.geolocation.country} </td>
                                                </tr>
                                                : null
                                        }

                                    </table>
                                    <Divider/>
                                    {
                                        (customer_info.last_visit.geolocation) ?
                                            <iframe
                                                frameBorder={0}
                                                width={'100%'}
                                                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCN4Q55YiJ0n9-HlJ0WojRnLRAnzgY89OU&amp;q=3.10689998,101.7136&amp;zoom=12"
                                            ></iframe>
                                            : null
                                    }


                                </div>
                            </Card>
                            <Card title="Technology">
                                <div>
                                    <table>
                                        <tr>
                                            <td>Name:</td>
                                            <td>{customer_info.name}</td>
                                        </tr>
                                        <tr>
                                            <td>IP:</td>
                                            <td>{customer_info.last_visit.ip}</td>
                                        </tr>
                                        {
                                            (customer_info.last_visit.geolocation) ?
                                                <tr>
                                                    <td>Location:</td>
                                                    <td>{customer_info.last_visit.geolocation.region},{customer_info.last_visit.geolocation.city},{customer_info.last_visit.geolocation.country} </td>
                                                </tr>
                                                : null
                                        }

                                    </table>
                                    <Divider/>

                                </div>
                            </Card>
                            {
                                (customer_info.last_visit.last_pages) ?
                                    customer_info.last_visit.last_pages.map((page, index) => {
                                        return (
                                            <Card title="Visited Page">
                                                <div>
                                                    <table>
                                                        <tr>
                                                            <td>{page.title}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><a href={page.url} target={'_blank'}>{page.url}</a></td>
                                                        </tr>

                                                    </table>
                                                </div>
                                            </Card>
                                        )
                                    })
                                    : null
                            }
                        </div>
                    </div>
                    : null

            }
            {/*Bottom Navigation Bar START*/
            }

            {
                (selectedTab === 'chats' || selectedTab === 'search') ?
                    <div id={'searchbar'}>
                        <FieldGroup inline stretch style={{backgroundColor: color_mode, padding: 5,}}>
                            <InputField
                                value={search_value}
                                error={search_value_error}
                                id='search_input'
                                placeholder='Search'
                                onChange={onInputChange}
                                style={{width: '100%'}}
                                autoComplete={"off"}
                                autoCorrect={"off"}
                                // onSubmit={() => alert('a')}
                                onKeyUp={(event) => handleKeyPress(event)}
                            />

                            <Button
                                disabled={(search_value.length <= 0) ? true : false}
                                onClick={() => {
                                    set_selectedTab('search');
                                    onSearch();
                                }} style={{backgroundColor: 'white', width: 50}}>
                                <FaSearch color={'#17A71B'}/>
                            </Button>
                        </FieldGroup>
                    </div> : null
            }

            {
                (selectedTab === 'list') ?
                    <div id={'listbar'}>
                        <FieldGroup inline stretch style={{backgroundColor: color_mode, padding: 5,}}>
                            <Button
                                disabled={(list.length <= 0) ? true : false}
                                onClick={() => set_list([])} style={{
                                borderColor: '#FAA628',
                                color: '#FAA628',
                                borderWidth: 0,
                                margin: 2
                            }}><FaCartPlus/> Clear</Button>
                            <Button
                                disabled={(list.length <= 0) ? true : false}
                                style={{
                                    width: 400, backgroundColor: '#FAA628', color: 'white', borderWidth: 0, margin: 2
                                }}><FaCartPlus/> Add to Cart</Button>
                            <Button onClick={() => sendRichMessage(list)} style={{
                                backgroundColor: '#FAA628',
                                color: 'white',
                                borderWidth: 0,
                                margin: 2

                            }}
                                    disabled={(list.length <= 0) ? true : false}
                            ><FaTelegramPlane/> Send</Button>
                        </FieldGroup>
                    </div> : null
            }

            {
                (selectedTab === 'cart') ?
                    <div id={'cart'}>
                    </div>
                    : null

            }
            {/*Bottom Navigation Bar END*/
            }

        </PluginHomeWrapper>

    )

}

export default PluginHome;
