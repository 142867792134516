import React from "react";
import {Button, Card} from "@livechat/design-system";
import styled from "styled-components";
import {FaFilePdf} from "react-icons/fa";
import moment from "moment";

const Link = styled.a`
  pointer-events: auto;
  cursor: pointer;
  color: rgb(67, 132, 245) !important;
  text-decoration: none !important;
  line-height: 1.27;
  display: inline-flex;
  word-break: break-all;
  width: 80%;
  float: left;
  align-self: center;
`;
const MessageWrapper = styled.div`
  padding-top: 20px;
  padding-bottol: 20px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: ${({isCustomer}) =>
          isCustomer ? "flex-start" : "flex-end"};
`;
const MessageAuthor = styled.small`
  position: absolute;
  top: -3px;
`;
const RichMessageBtn = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  margin: 0px auto;
  overflow: hidden;
  width: 100%;
  height: 40px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  color: white;
  background: ${({isCustomer, theme}) =>
          isCustomer ? theme.secondary : theme.primary};
`;
const Attachment = ({message, user}) => {
        const userName = (user && user.name) || "";
        const isCustomer = message && message.user_type === "customer";
        const event_created_date = message.event_created_date || "";
        const time = message.created_at || "";
        let {url} = message
        let url_arr = url.split('.');
        let filename_arr = url.split('/');
        let filename = decodeURIComponent(filename_arr[filename_arr.length - 1]);
        if (url_arr[url_arr.length - 1] === 'jpg' || url_arr[url_arr.length - 1] === 'jpeg') message.content_type = 'image/jpg';
        if (url_arr[url_arr.length - 1] === 'png' || url_arr[url_arr.length - 1] === 'png') message.content_type = 'image/png';
        if (url_arr[url_arr.length - 1] === 'gif' || url_arr[url_arr.length - 1] === 'gif') message.content_type = 'image/gif';
        if (url_arr[url_arr.length - 1] === 'pdf' || url_arr[url_arr.length - 1] === 'pdf') message.content_type = 'application/pdf';
        var convert_date = moment(event_created_date).format('YYYY-MM-DD HH:mm:ss')

        return (
            <MessageWrapper isCustomer={isCustomer} style={{margin: 10}}>
                    <MessageAuthor>{(message.user_type === 'agent') ? 'Medisave Support' : message.user_name} {convert_date} </MessageAuthor>
                    {
                    (message.content_type === 'application/pdf') ?

                        <Card style={{
                                marginBottom: 10,
                                maxWidth: 280,
                        }}>

                                <div style={{float: 'left', width: '20%'}}><FaFilePdf color={'#AD0B00'} size={30}/>
                                </div>
                                <div style={{float: 'left', width: '80%'}}>
                                        {/*{JSON.stringify(message)}*/}
                                        <div style={{width: '100%', display: 'inline-block'}}>
                                                <Link href={message.url} download={true} target={'_blank'}>
                                                        {filename}
                                                </Link>
                                        </div>
                                        <div style={{
                                                width: '100%',
                                                display: 'inline-block',
                                                fontSize: 10,
                                                color: "grey"
                                        }}>
                                                {(parseFloat(message.size) / 1024).toFixed(2)} KB
                                        </div>
                                </div>
                                <Link href={message.url} target={'_blank'} style={{width: '100%', marginTop: 4}}>
                                        <Button kind="primary" style={{width: '100%'}}>Download</Button>
                                </Link>
                        </Card> : null
                }
                {
                    (message.content_type === 'image/png' || message.content_type === 'image/jpg' || message.content_type === 'image/jpeg' || message.content_type === 'image/gif') ?
                        <Card style={{
                            marginBottom: 10, width: 150, alignContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'center',
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <Link href={message.url} target={'_blank'} download style={{width: '100%'}}>
                                <img src={message.thumbnail_url}
                                     width={150}></img>
                            </Link>
                            <Link href={message.url} target={'_blank'} style={{width: '100%', marginTop: 4}}>
                                <Button kind="primary" style={{width: '100%'}}>Download</Button>
                            </Link>
                        </Card>
                        : null
                }

            </MessageWrapper>
        )
    }
;

export default Attachment;
