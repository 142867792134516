import React, {useState} from "react";
import {Button, Dropdown, DropdownList, Form, InputField, PopperTooltip} from "@livechat/design-system";
import {ChatSDK} from "../../Logic";
import {FaHashtag, FaPaperclip, FaWhatsapp} from "react-icons/fa";
import {FilePicker} from 'react-file-picker'

const isBoolean = (param) => {
    return typeof param === "boolean"
}
const useFocus = (initialFocus = false, id = "") => {
    const [focus, setFocus] = useState(initialFocus)
    const setFocusWithTrueDefault = (param) => setFocus(isBoolean(param) ? param : true)
    return ([
        setFocusWithTrueDefault, {
            autoFocus: focus,
            key: `${id}${focus}`,
            onFocus: () => setFocus(true),
            onBlur: () => setFocus(false),
        },
    ])
}
const ChatForm = ({chatId, selected_user_info}) => {
    let {mobile_no} = selected_user_info
    if (!mobile_no) mobile_no = '';
    if (mobile_no.length == 8) {
        mobile_no = '65' + mobile_no
    } else if (mobile_no[0] == 1) {
        mobile_no = '60' + mobile_no
    } else {
        mobile_no = '62' + mobile_no

    }


    console.log(mobile_no)

    const [inputValue, setInputValue] = useState("");
    const [isVisible, setIsVisible] = useState(false);
    const [search_keyword, set_search_keyword] = useState('');
    const [canned_response, set_canned_response] = useState([
        {
            "id": 1,
            "group": 0,
            "text": "Can I help you with anything else?",
            "modification_date": 1602336284,
            "tags": [
                "help",
                "else"
            ]
        },
        {
            "id": 2,
            "group": 0,
            "text": "What product are you interested in?",
            "modification_date": 1602336284,
            "tags": [
                "product",
                "interest"
            ]
        },
        {
            "id": 3,
            "group": 0,
            "text": "I will transfer you to the agent responsible for this matter.",
            "modification_date": 1602336284,
            "tags": [
                "transfer"
            ]
        },
        {
            "id": 4,
            "group": 0,
            "text": "Thank you for your feedback.",
            "modification_date": 1602336284,
            "tags": [
                "thank",
                "feedback"
            ]
        },
        {
            "id": 5,
            "group": 0,
            "text": "Thank you for your patience.",
            "modification_date": 1608892054,
            "modified_by": "literyi@hotmail.com",
            "tags": [
                "patient"
            ]
        },
        {
            "id": 6,
            "group": 0,
            "text": "It was nice talking to you. Have a great day, goodbye!",
            "modification_date": 1602336284,
            "tags": [
                "goodbye",
                "bye",
                "cu"
            ]
        },
        {
            "id": 7,
            "group": 0,
            "text": "You're welcome.",
            "modification_date": 1602336284,
            "tags": [
                "welcome"
            ]
        },
        {
            "id": 8,
            "group": 0,
            "text": "See you later!",
            "modification_date": 1602336284,
            "tags": [
                "later",
                "cu"
            ]
        },
        {
            "id": 10,
            "group": 0,
            "text": "Congratulation, your application has been approved. Please login from https://medisave.pro/login",
            "creation_date": 1608194540,
            "created_by": "sgchua@happypocket.club",
            "modification_date": 1608194596,
            "modified_by": "sgchua@happypocket.club",
            "tags": [
                "approved"
            ]
        },
        {
            "id": 11,
            "group": 0,
            "text": "Thank you for your payment. Your order will be processed once we receive the payment. ",
            "creation_date": 1608265132,
            "created_by": "literyi@hotmail.com",
            "tags": [
                "Payment "
            ]
        },
        {
            "id": 12,
            "group": 0,
            "text": "Please attach your license A or APC in this chat room. ",
            "creation_date": 1608544724,
            "created_by": "literyi@hotmail.com",
            "modification_date": 1608710786,
            "modified_by": "literyi@hotmail.com",
            "tags": [
                "license"
            ]
        }
    ]);
    const [setFocus, focusProps] = useFocus(true)

    const handleInputChange = ({target}) => {
        let string_arr = target.value.split(' ')
        if (string_arr[string_arr.length - 1].charAt(0) === '#') {
            set_search_keyword(string_arr[string_arr.length - 1].substring(1))
        }
        setInputValue(target.value)
    };

    const sendMessage = e => {
        e.preventDefault();
        ChatSDK.sendMessage(chatId, inputValue).then(event => {
            setInputValue("");

        })
            .catch(response => {
                if (window.confirm(response.payload.error.message + '. Would you like to contact client from Whatsapp?')) {
                    window.open("https://api.whatsapp.com/send?text=" + inputValue + "&phone=" + mobile_no);
                    setInputValue("");

                }
            });
    };
    const concat_input_value = (canned_response) => {
        setInputValue((prevState) => prevState + ' ' + canned_response)
    }
    const uploadFile = (FileObject) => {
        let token = localStorage.getItem('accessToken')

        var myHeaders = new Headers();
        myHeaders.append("Authorization", 'Bearer ' + token);

        var formdata = new FormData();
        formdata.append("file", FileObject, FileObject.name);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://api.livechatinc.com/v3.2/agent/action/upload_file",
            requestOptions)
            .then(response => response.text())
            .then(result => {
                let return_data = (JSON.parse(result))
                send_attachment(return_data.url)
            })
            .catch(error => console.log('error', error));
    }
    const send_attachment = (url) => {
        let token = localStorage.getItem('accessToken')
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", 'Bearer ' + token);

        var raw = JSON.stringify({
            "chat_id": chatId,
            "event": {
                recipients: "all",
                type: "file",
                url: url
            }
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://api.livechatinc.com/v3.2/agent/action/send_event", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.error) {
                    if (window.confirm(result.error.message + '. Would you like to contact client from Whatsapp?')) {
                        window.open("https://api.whatsapp.com/send?phone=" + mobile_no);
                    }
                }
            })
            .catch(response => {

                if (window.confirm(response.message + '. Would you like to contact client from Whatsapp')) {
                    window.open("https://api.whatsapp.com/send?phone=" + mobile_no);
                }
            });
    }
    const handleClose = () => {
        setIsVisible(false);
    }
    const handleTriggerClick = () => {
        setIsVisible(true)
    }

    return (
        <Form onSubmit={sendMessage} style={{width: "100%", flexDirection: 'row',}}>

            <div style={{
                display: 'inline-block',
                padding: 2,
                border: '1px solid lightgray',
                width: '98%',
                borderRadius: 5,
                borderColor: 'lightgray'
            }}>

                <InputField
                    autoComplete={"off"}
                    autoCorrect={"off"}
                    autoFocus={true}
                    spellCheck={"off"}
                    disabled={!chatId}
                    value={inputValue}
                    id="chat-message"
                    placeholder="Write message..."
                    onChange={handleInputChange}
                    {...focusProps}
                    style={{border: 0, width: "100%", marginTop: "auto", flexDirection: 'row',}}
                />
                <div style={{}}>
                    <SelectableDropdownList data={canned_response} search_keyword={search_keyword}
                                            callback={concat_input_value}/>
                    <Button style={{border: 0}}>
                        <FilePicker
                            extensions={['jpeg', 'jpg', 'png', 'gif', 'pdf']}
                            onChange={FileObject => {
                                uploadFile(FileObject)
                            }}
                            onError={errMsg => {
                                alert(errMsg)
                            }}
                            style={{cursor: 'pointer', float: 'left'}}
                        >
                            <FaPaperclip color={'gray'}/>
                        </FilePicker>
                    </Button>
                    <Button onClick={() => window.open("https://api.whatsapp.com/send?phone=" + mobile_no)}
                            style={{border: 0}}><div style={{cursor: 'pointer', float: 'left'}}><FaWhatsapp color={'gray'}/></div></Button>


                    <Button disabled={(inputValue.length > 0) ? false : true} kind="primary" style={{float: 'right'}}
                            type={"submit"}>Send</Button>

                </div>

            </div>
            <PopperTooltip
                style={{textAlign: "left"}}
                isVisible={true}
                placement={"auto-start"}
                onClose={handleClose}
                // triggerActionType={"hover"}
                // trigger={this.renderTrigger()}
                closeOnOutsideClick
                closeWithX
                closeWithEsc
                zIndex={9999}
            >
                <div>
                    Attachment
                </div>
            </PopperTooltip>
        </Form>
    );
};


const getListItems = (onItemSelect, return_data) => {
    let data = []
    return_data.map((item) => {
        data.push({
            itemId: item.id,
            content: item.text,
            onItemSelect: onItemSelect,
        });
    })
    return data;
};


const SelectableDropdownList = (props) => {

    const callback = props.callback
    const data = props.data
    const search_keyword = props.search_keyword
    const handleItemSelect = (id) => {
        let clone = [...listItems]
        let content = clone.find(x => x.itemId === id)
        setIsVisible(false)
        callback(content.content)
    }
    const [isVisible, setIsVisible] = useState(false);
    const [listItems, set_listItems] = useState(getListItems(handleItemSelect, props.data));
    React.useEffect(() => {
        if (search_keyword.trim().length > 0) setIsVisible(true)
    }, [props.search_keyword])

    const handleClose = () => {
        setIsVisible(false)
    }

    const handleTriggerClick = () => {
        setIsVisible(prevState => !prevState);
    }


    return (

        <Dropdown
            style={{float: 'left'}}
            isVisible={isVisible}
            placement="bottom-start"
            onClose={handleClose}
            triggerRenderer={({ref}) => <Button
                style={{border: 0, float: 'left'}}
                onClick={handleTriggerClick} ref={ref}><FaHashtag color={'gray'}
            /></Button>}
            // triggerRenderer={({ref}) =><div ref={ref} style={{width:'100%'}}></div>}
        >
            <DropdownList items={listItems}/>
        </Dropdown>


    )

}


export default ChatForm;
