import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {ChatSDK} from "../../Logic";

// COMPONENTS:
import ChatForm from "./Form";
import Message from "../Events/Message";
import ChatInstruction from "./ChatInstruction";
import PluginHome from "./../Plugin/PluginHome";
import {TabsList, TabsWrapper} from "@livechat/design-system";
import Attachment from "../Events/Attachment";
import RichMessage from "../Events/RichMessage";

// STYLED COMPONENTS:
const Wrapper = styled.div`
  height: 94%;
  width: calc(100% - 0px);
  // padding: 0.5rem 1rem;
`;

const MessageWrapper = styled.div`
  height: 95%;
  overflow-y: auto;
  overflow-x: hidden;
  float: left;
  width: 100%;

  & > .lc-card {
    background: ${({
                     theme
                   }) => theme.secondary};
    display: inline-block;
    margin-top: 0;
    margin-bottom: 1rem;
  }
`;

const OverallWrapper = styled.div`
  width: ${({
              onlyMessages
            }) => (onlyMessages ? "99%" : "60%")};
  height: ${({
               onlyMessages
             }) => (onlyMessages ? "100%" : "89%")};
  float: left;
  display: inline-block;

`;

const ChatMessages = ({selected_user_id, selected_user_info}) => {
        const [chatMessages, setChatMessages] = useState(null);
        const [event, set_event] = useState(null);
        let onlyMessages = false
        let color_mode = '#319C60';
        let {user_name, domain, mobile_no, user_id} = selected_user_info;
        if (domain.includes('medisave.store'))
            color_mode = '#2660de'
        if (domain.includes('medisave.shop'))
            color_mode = '#fd6701'
        const ref = useRef();


        const retrieve_data = (user_id) => {
            var formdata = new FormData();
            // alert(user_id)
            formdata.append("action", "retrieve_chat");
            formdata.append("user_id", user_id);
            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };
            fetch("https://chatapi.happypocket2u.com/get_info.php", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (!chatMessages || result.data[0] != chatMessages[0]) {
                        setChatMessages(result.data)
                        ref.current.scrollTo(0, ref.current.scrollHeight);
                    }
                })
                .catch(error => console.log('error', error));
        }

        const refresh_data = React.useCallback(({payload}) => {


            set_event(payload)
        }, [])

        useEffect(() => {
            // ChatSDK.off("incoming_event", refresh_data);
            // ChatSDK.off("incoming_chat_thread", refresh_data);
            // ChatSDK.off("chat_thread_properties_updated", refresh_data);
            ChatSDK.on("incoming_event", refresh_data);
            ChatSDK.on("incoming_chat_thread", refresh_data);
            ChatSDK.on("chat_thread_properties_updated", refresh_data);
        }, [])
        useEffect(() => {
            setChatMessages(null)
        }, [selected_user_id])
        useEffect(() => {
            //
            retrieve_data(selected_user_id)
        }, [selected_user_id, event])


        return (
            <Wrapper>
                <OverallWrapper onlyMessages={onlyMessages}>
                    <TabsWrapper>
                        <TabsList
                            style={{
                                padding: 5,
                                justifyContent: 'center',
                                fontWeight: 'bold',

                            }}>
                            {selected_user_info.user_name} {selected_user_id}
                        </TabsList>
                    </TabsWrapper>
                    <MessageWrapper ref={ref}>

                        {chatMessages && chatMessages.map((message, index) => {

                            if (selected_user_info.user_type !== '') {
                                switch (message.event_type) {
                                    case "message":
                                        return (<Message
                                            key={index}
                                            message={message}
                                            user={selected_user_info}
                                            color_mode={color_mode}/>);

                                    // case "system_message":
                                    //     return <SystemMessage
                                    //         key={index}
                                    //         message={message}
                                    //         user={selected_user_info}
                                    //         color_mode={color_mode}/>;

                                    // case "filled_form":
                                    //     return <FilledForm
                                    //         key={index}
                                    //         message={message}
                                    //         user={selected_user_info}
                                    //         color_mode={color_mode}/>;

                                    case "file":
                                        return <Attachment
                                            key={index}
                                            message={message}
                                            user={selected_user_info}
                                            color_mode={color_mode}/>;

                                    case "rich_message":
                                        return <RichMessage
                                            key={message.thread_id}
                                            message={message}
                                            user={selected_user_info}
                                            color_mode={color_mode}/>;

                                    default:
                                        return null;
                                }
                            }

                        })}

                        {chatMessages && !chatMessages.length && (<ChatInstruction/>)}

                    </MessageWrapper>
                </OverallWrapper>
                {(!onlyMessages && chatMessages)
                    ? <PluginHome
                        chatMessages={chatMessages}
                        chatInfo={selected_user_info}
                        color_mode={color_mode}
                        domain={domain}/>
                    : null}
                {!onlyMessages && <ChatForm color_mode={color_mode} chatId={selected_user_info.chat_id}
                                            selected_user_info={selected_user_info}/>}
            </Wrapper>
        );
    }
;

export default ChatMessages;
