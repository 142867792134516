import React, {useCallback, useEffect} from "react";
import {Badge} from "@livechat/design-system";
import styled from 'styled-components';
import ReactList from 'react-list';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar';

const ListWrapper = styled.div`
  margin-right: 1rem;
  width: 300px;
  border-right: 1px solid ${({
                               theme
                             }) => theme.secondary};
  overflow-y: auto;
`;

const ChatList = ({chatList, selected_user_id, pickChat, set_selected_user_info, setChatList}) => {

    useEffect(() => {
        //     if (set_page) {
        //         myscroll
        //             .current
        //             .addEventListener("scroll", () => {
        //                 if (myscroll.current.scrollTop + myscroll.current.clientHeight >= myscroll.current.scrollHeight) {
        //                     set_page()
        //                 }
        //             });
        //     }
    }, [])
    const update_seen_status = useCallback((user_id) => {
        var formdata = new FormData();
        formdata.append("action", "update_seen_status");
        formdata.append("user_id", user_id);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://chatapi.happypocket2u.com/get_info.php", requestOptions)
            .then(response => response.text())
            .then(result => {
                let temp_chatList = [...chatList];
                let total_notification_count = 0;

                temp_chatList.map((item, index) => {
                    if (item.user_id == user_id) {
                        temp_chatList[index].notification_count = 0;
                    } else {
                        total_notification_count += parseInt(temp_chatList[index].notification_count);
                    }
                })
                if (total_notification_count > 0) {
                    document.title = '(' + total_notification_count + ') Medisave Live Chat'
                } else {
                    document.title = 'Medisave Live Chat'
                }
                setChatList(temp_chatList)
            })
            .catch(error => console.log('error', error));
    }, [chatList])

    const renderItem = useCallback((index, key) => {
        let isActive = false;
        let color_mode = '#319C60';

        let {user_name, domain, mobile_no, user_id, notification_count} = chatList[index];
        if (domain.includes('medisave.store'))
            color_mode = '#2660de'
        if (domain.includes('medisave.shop'))
            color_mode = '#fd6701'
        if (selected_user_id === user_id) isActive = true;

        return (
            <div>
                <ListItem
                    alignItems="flex-start"
                    key={index}
                    onClick={() => {
                        pickChat(user_id)
                        set_selected_user_info(chatList[index])
                        update_seen_status(user_id);
                    }}
                    style={{
                        cursor: 'pointer',
                        fontSize: '100%',
                        backgroundColor: (isActive)
                            ? color_mode
                            : null
                    }}>
                    <ListItemAvatar>
                        <Avatar
                            style={{
                                color: (isActive) ? color_mode : 'white',
                                backgroundColor: (isActive) ? 'white' : color_mode
                            }}
                            alt={(user_name)
                                ? user_name
                                : 'Visitor'}
                            src="/static/images/avatar/1.jpg"/>
                    </ListItemAvatar>
                    <ListItemText
                        primary={<span
                            style={{
                                color: (isActive) ? 'white' : color_mode,
                                fontSize: 14
                            }}>{user_name} | {mobile_no} | {user_id}</span>}
                        secondary={<span style={{color: 'lightgrey', fontSize: 10}}>{domain}</span>}>
                    </ListItemText>

                    {
                        (notification_count > 0) ?
                            <ListItemSecondaryAction>
                                <Badge>{notification_count}</Badge>
                            </ListItemSecondaryAction>
                            : null
                    }

                    {/*{JSON.stringify(chatList[index])}*/}
                </ListItem>
                <Divider/>

            </div>
        );
    }, [selected_user_id, chatList])
    return (
        <ListWrapper>
            <List>
                <ReactList itemRenderer={renderItem} length={chatList.length} type='uniform'/>
            </List>
        </ListWrapper>
    )
}

export default ChatList;
