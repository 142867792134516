import React, {useEffect, useState} from "react";
import {ChatSDK} from "../Logic";
import styled from "styled-components";

// COMPONENTS:
import ChatsList from "../Components/Chat/List";
import ChatMessages from "../Components/Chat/Messages";

// CUSTOM HOOKS:
import {useChatList, useChatMessages} from "../Hooks";

// STYLED COMPONENTS:
const Wrapper = styled.div`
  display: flex;
  height: 90%;
  min-height: 400px;
  padding: 1rem;
`;

const ActiveChats = (props) => {
    // const {select_chat_id}=props

    const [isReady, setIsReady] = useState(false);
    const [selected_user_id, set_selected_user_id] = useState(null);
    const [selected_user_info, set_selected_user_info] = useState(null);
    const [chatList, setChatList] = useState([]);
    const [total_notification, set_total_notification] = useState('Medisave Live Chat');

    const retrieve_user = React.useCallback((payload) => {
        if (payload) {
            var requestOptions = {
                method: 'POST',
                body: JSON.stringify(payload),
                redirect: 'follow'
            };

            fetch("https://chatapi.happypocket2u.com/", requestOptions)
                .then(response => response.text())
                .then(result => console.log(result))
                .catch(error => console.log('error', error));
        }


        var formdata = new FormData();
        formdata.append("action", "retrieve_user");

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };
        let total_notification_count = 0;

        fetch("https://chatapi.happypocket2u.com/get_info.php", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (chatList.length <= 0) {
                    setChatList(result.data)
                } else {
                    if (chatList && chatList[0].user_id !== result.data[0].user_id) {
                        setChatList(result.data)
                        chatList.map((chat) => {
                            if (chat.user_id === selected_user_id) {
                                set_selected_user_info(chat)
                            }
                        })
                    }
                }
                result.data.map((chat) => {
                    // console.log(chat)
                    total_notification_count += parseInt(chat.notification_count)
                })
                if (total_notification_count > 0) {
                    set_total_notification('(' + total_notification_count + ') Medisave Live Chat')

                } else {
                    set_total_notification('Medisave Live Chat')
                }
                if (total_notification_count > 0) {
                    document.title = '(' + total_notification_count + ') Medisave Live Chat'
                } else {
                    document.title = 'Medisave Live Chat'
                }

            })
            .catch(error => console.log('error', error));
    }, [])

    useEffect(() => {
        retrieve_user();
        ChatSDK.off("incoming_event", retrieve_user);
        ChatSDK.off("incoming_chat_thread", retrieve_user);
        ChatSDK.off("chat_thread_properties_updated", retrieve_user);
        ChatSDK.on("incoming_event", retrieve_user);
        ChatSDK.on("incoming_chat_thread", retrieve_user);
        ChatSDK.on("chat_thread_properties_updated", retrieve_user);
    }, [])

    const pickChat = (user_id) => {
        set_selected_user_id(user_id);
    }

    useChatList();
    useChatMessages();


    return (
        <Wrapper>
            {/*<Helmet>*/}
            {/*    <title>{total_notification}</title>*/}
            {/*</Helmet>*/}
            <ChatsList chatList={chatList} selected_user_id={selected_user_id} pickChat={pickChat}
                       set_selected_user_info={set_selected_user_info} setChatList={setChatList}/>
            {
                (selected_user_info && selected_user_id) ?
                    <ChatMessages selected_user_id={selected_user_id} selected_user_info={selected_user_info}/> : null
            }
        </Wrapper>
    );
};

export default ActiveChats;
