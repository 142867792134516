import {useEffect, useRef, useState} from "react";
import {accountsSdk} from "@livechat/accounts-sdk";

const initialAuthState = {
    isLoggingIn: true,
    isLoggedIn: false,
    agentData: null,
};

export const useAuth = () => {
    const [auth, setAuth] = useState(initialAuthState);
    const instance = useRef(null);

  useEffect(() => {
    instance.current = accountsSdk.init({
      client_id: process.env.REACT_APP_CLIENT_ID,
      onIdentityFetched: async (error, data) => {
        if (error) {
          console.error('Authorization problem: ', error)
          instance.current.openPopup();
          setAuth(initialAuthState);
          return;
        }
        if (data && data.access_token) {
            localStorage.setItem('accessToken', data.access_token)
            localStorage.setItem('clientID', process.env.REACT_APP_CLIENT_ID)

            var formdata = new FormData();
            formdata.append("action", "insert_accesstoken");
            formdata.append("accesstoken", data.access_token);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch("https://chatapi.happypocket2u.com/get_info.php", requestOptions)
                .then(response => response.text())
                .then(result => console.log(result))
                .catch(error => console.log('error', error));


            setAuth({
                isLoggingIn: false,
                isLoggedIn: true,
                agentData: data,
            });
        } else {
          setAuth(initialAuthState);
          instance.current.openPopup();

        }
      }
    });
  }, []);

  return {
    ...auth,
    instance: instance.current || null
  };
};
